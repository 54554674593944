import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    px: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Description = styled(Box).attrs(function (props) {
  return _extends({
    color: 'text.base',
    fontWeight: 'regular',
    lineHeight: 'big',
    textAlign: 'justify'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var WarningGenAI = {
  color: '#69727763',
  fontWeight: '400',
  lineHeight: '18px',
  marginBottom: '0px',
  marginTop: '32px'
};