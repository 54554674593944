import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { StarGroup } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ProductBundleSelector from '../ProductBundleSelector';
import VisibilitySection from '../VisibilitySection';
import RichDescription from '../RichDescription';
import GenericButton from '../GenericButton';
import { useReviewSummary } from '@magalu/mixer-graphql';
import { Content, Description, WarningGenAI } from './ReviewSummary.styles';
var baseTitle = {
  color: 'base',
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'display',
  my: [2, 2, 3, 3]
};
function ReviewSummary(_ref) {
  var product = _ref.data.product,
    staticProps = _ref.staticProps,
    route = _ref.structure.route;
  var _useReviewSummary = useReviewSummary(),
    reviewSummary = _useReviewSummary.reviewSummary,
    isLoading = _useReviewSummary.isLoading,
    error = _useReviewSummary.error,
    getReviewSummary = _useReviewSummary.getReviewSummary;
  var elemRef = useRef(null);
  useEffect(function () {
    getReviewSummary(product.id);
  }, [product.id]);
  var buttonProps = _extends({}, staticProps.genericButtonProps, {
    href: "/review/".concat(route.productId, "/").concat(route.slug, "/").concat(route.categoryId, "/").concat(route.subCategoryId)
  });
  if (!reviewSummary || error || isLoading) return null;
  return React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:detail"
  }, React.createElement(Content, {
    "data-testid": "summary-detail",
    ref: elemRef
  }, staticProps.label && React.createElement(Heading, _extends({}, baseTitle, {
    fontSize: [2, 2, 5, 5],
    fontWeight: "medium",
    "data-testid": "summary-detail-label",
    as: staticProps.labelAs || 'h2'
  }), staticProps.label), React.createElement(ProductBundleSelector, {
    data: product
  }), React.createElement(Description, {
    fontSize: [1, 1, 2, 2],
    "data-testid": "summary-detail-description"
  }, React.createElement(RichDescription, {
    "static": {
      deniedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'div', 'img', 'svg'],
      richContent: reviewSummary.summary
    }
  })), React.createElement("div", {
    style: _extends({}, WarningGenAI, {
      fontSize: '2'
    })
  }, React.createElement(StarGroup, null), "Resumo das avalia\xE7\xF5es do produto feito por intelig\xEAncia artificial, com base nas avalia\xE7\xF5es de clientes reais"), React.createElement(GenericButton, {
    "static": buttonProps
  })));
}
ReviewSummary.ssr = true;
ReviewSummary.defaultProps = {
  staticProps: {
    genericButtonProps: {
      as: 'a',
      backgroundColor: 'blue',
      borderRadius: 6,
      boxShadow: 'inset 0 0 0 1px #0086ff',
      color: 'white',
      fontSize: '16px',
      full: 'full',
      height: '52px',
      href: '',
      label: 'Ver todas as avaliações',
      lineHeight: 'display',
      margin: '70px 0',
      marginTop: '32px',
      minWidth: '440px',
      size: 'large',
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 1px #0086ff',
        color: '#0086ff',
        height: '52px',
        marginTop: '32px'
      },
      uppercase: false,
      variation: 'outline'
    },
    label: '🤩 O que as pessoas mais gostaram no produto',
    labelAs: 'h2'
  }
};
process.env.NODE_ENV !== "production" ? ReviewSummary.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      brand: PropTypes.shape({
        slug: PropTypes.string.isRequired
      }).isRequired,
      category: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired,
      subcategory: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  staticProps: PropTypes.shape({
    genericButtonProps: PropTypes.shape({
      as: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.number,
      boxShadow: PropTypes.string,
      color: PropTypes.string,
      fontSize: PropTypes.string,
      full: PropTypes.string,
      height: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      lineHeight: PropTypes.string,
      margin: PropTypes.string,
      marginTop: PropTypes.string,
      minWidth: PropTypes.string,
      size: PropTypes.string,
      style: PropTypes.objectOf(PropTypes.any),
      uppercase: PropTypes.bool,
      variation: PropTypes.string
    }),
    label: PropTypes.string,
    labelAs: PropTypes.string
  }),
  structure: PropTypes.shape({
    route: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subCategoryId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
} : void 0;
export default withLayoutProps(ReviewSummary);