import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect, useRef } from 'react';
import { shape } from 'prop-types';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import StereoHeading from '@magalu/stereo-ui/atoms/Heading';
import { Flex, Text } from '@magalu/stereo-ui/atoms';
import { scrollToRef } from '@magalu/mixer-utils';
import RatingLineGroup from '../RatingLineGroup';
import ReviewListing from '../ReviewListing';
import ReviewTotalizers from '../ReviewTotalizers';
import GenericButton from '../GenericButton';
import { usePublisher } from '@magalu/mixer-publisher';
import { Content, Divider, MainContentReview, TotalLabel } from './ReviewPdpSection.styles';
var ReviewPdpSection = function ReviewPdpSection(_ref) {
  var _data$productRating, _data$productRating$g;
  var data = _ref.data,
    staticProps = _ref["static"];
  var total = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$g = _data$productRating.general) == null ? void 0 : _data$productRating$g.commentCount) || 0;
  var elemRef = useRef(null);
  var title = staticProps.title,
    marginBottomScroll = staticProps.marginBottomScroll;
  var ratingLineGroupProps = staticProps.ratingLineGroupProps,
    genericButtonProps = staticProps.genericButtonProps;
  var handleClickEvent = function handleClickEvent() {
    return scrollToRef(elemRef, marginBottomScroll);
  };
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  useEffect(function () {
    subscribe('product:review:click', handleClickEvent);
    return function () {
      unsubscribe('product:review:click', handleClickEvent);
    };
  });
  return total > 0 && React.createElement(MainContentReview, {
    ref: elemRef,
    yOffset: marginBottomScroll
  }, React.createElement(StereoHeading, {
    as: "h2"
  }, title), React.createElement(Grid, {
    mt: 4,
    gridGap: "0 8rem",
    gridTemplateColumns: "2fr 4fr"
  }, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Flex, null, React.createElement(ReviewTotalizers, {
    data: data
  })), React.createElement(Divider, {
    backgroundColor: "primary.dark"
  }), React.createElement(Content, {
    flexDirection: "column"
  }, React.createElement(Text, {
    color: "inherit",
    fontWeight: "bold",
    fontSize: 3,
    marginBottom: 4
  }, "Classifica\xE7\xE3o"), React.createElement(RatingLineGroup, _extends({}, ratingLineGroupProps, {
    data: data
  })))), React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(TotalLabel, {
    "data-testid": "review-total",
    fontSize: 3
  }, total, "\xA0", total === 1 ? 'comentário' : 'comentários'), React.createElement(Divider, {
    backgroundColor: "primary.dark"
  }), React.createElement(Content, null, React.createElement(ReviewListing, {
    data: data
  })), React.createElement(GenericButton, genericButtonProps))));
};
ReviewPdpSection.ssr = true;
ReviewPdpSection.displayName = 'ReviewPdpSection';
ReviewPdpSection.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  productRating: {
    query: 'ProductRatingQuery'
  }
};
ReviewPdpSection.defaultProps = {
  data: {},
  "static": {}
};
process.env.NODE_ENV !== "production" ? ReviewPdpSection.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
export default ReviewPdpSection;